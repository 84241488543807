import React, { useState, useContext, useEffect } from 'react';
import './../ChangePassword.css';
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import { WebSocketContext } from "../WebSocketContext";
import { useNavigate, useLocation } from 'react-router-dom';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { userDetails } = useContext(WebSocketContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handlePath = (path) => {
    setValue(path);
    navigate(path);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("new Password", newPassword);
    // Handle form submission logic here
    const data = {
        oldPass: oldPassword,
        newPass: newPassword,
        cPass: confirmPassword,
        user_id: userDetails.clientId, // Assuming user_id is stored in sessionStorage
      };

      //console.log("change password:", data); return;
  
      try {
        const response = await fetch(`${config.API_BASE_URL}changePassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        
          const res_data = await response.json();
          if (res_data.success) {
            alert(res_data.message);
            handlePath('/');
            //window.location.href = 'logout_app.php'; // Redirect to logout
          } else {
            alert(res_data.message);
          }
        
      } catch (error) {
        alert('Error in updating password..');
        console.error('Error:', error);
      }
  };

  return (
    <div className="change-password-container">
      <form className="change-password-form" onSubmit={handleSubmit}>
        <h2>Change Password</h2>
        <label htmlFor="old-password">Old Password</label>
        <input
          type="password"
          id="old-password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <label htmlFor="new-password">New Password</label>
        <input
          type="password"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <label htmlFor="confirm-password">Confirm Password</label>
        <input
          type="password"
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit">Change Password</button>
        {/* <p className="note">You are required to change password here.</p> */}
      </form>
    </div>
  );
};

export default ChangePassword;
