import React, { useState, } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import './../Login.css';
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import etiStaakam from '../assets/etiStaakam.png';
import blue_rock_icon from '../assets/blue_rock_icon.png';

import { getToken, storeToken, removeToken } from '../utils/auth'; // Adjust path as needed


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  //const { login } = useAuth();
  //const token = getToken();
  const token = localStorage.getItem('authToken');

  console.log("login token", token);
  
  const navigate = useNavigate();

//   const handleLogin = (token, clientId) => {
//     login(token, clientId);
//     navigate('/');
//   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      
        const resData = await response.json();
        if (resData.success) {
          //alert('Login successful!');
          
          storeToken(resData);

          navigate('/?appilix_push_notification_user_identity='+resData.clientId);
          //handleLogin(resData.token, resData.clientId);
          
        } else {
          alert('Login failed: ' + resData.message);
        }
      
    } catch (error) {
      alert('Error in login.');
      console.error('Error:', error);
    }
  };

 
  return token ? (
    <Navigate to="/" />
  ) :  (
    <div className="login-container">
      <div style={{width:'100%', textAlign:'center'}}>
          <img src={blue_rock_icon} style={{maxHeight:'80px', marginBottom:'20px'}} />
          <form className="login-form" onSubmit={handleSubmit}>
            <h2>Login</h2>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Login</button>
          </form>
      </div>
    </div>
  );
};

export default Login;
