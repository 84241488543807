// WebSocketContext.js

// import React, { createContext, useState, useEffect } from "react";
// import WebSocketService from "./websocketService";

// const WebSocketContext = createContext();

// const WebSocketProvider = ({ children, resource, stockIDs }) => {
//   const [stocks, setStocks] = useState([]);
//   const [lastUpdateTime, setLastUpdateTime] = useState(null);

//   useEffect(() => {
//     const updateStockData = (data) => {
//       // Your logic to update stocks based on data
//       console.log("Stock data received: ", data);
//       // Update stocks state here
//     };

//     const wsService = new WebSocketService(
//       resource,
//       stockIDs,
//       updateStockData,
//       setLastUpdateTime
//     );
//     wsService.connect();

//     return () => {
//       wsService.disconnect();
//     };
//   }, [resource, stockIDs]);

//   return (
//     <WebSocketContext.Provider value={{ stocks, lastUpdateTime }}>
//       {children}
//     </WebSocketContext.Provider>
//   );
// };

// export { WebSocketProvider, WebSocketContext };


// import React, { createContext, useState, useEffect } from "react";
// import WebSocketService from "./websocketService";

// const WebSocketContext = createContext();

// const WebSocketProvider = ({ children, resource, stockIDs }) => {
//   const [stocks, setStocks] = useState([]);
//   const [lastUpdateTime, setLastUpdateTime] = useState(null);

//   useEffect(() => {
//     const updateStockData = (data) => {
//       console.log("Stock data received: ", data);
//       setStocks((prevStocks) => {
//         // Logic to update stocks based on received data
//         return [...prevStocks, data]; // Adjust this logic as needed
//       });
//       setLastUpdateTime(new Date());
//     };

//     const wsService = new WebSocketService(
//       resource,
//       stockIDs,
//       updateStockData,
//       setLastUpdateTime
//     );
//     wsService.connect();

//     return () => {
//       wsService.disconnect();
//     };
//   }, [resource, stockIDs]);

//   return (
//     <WebSocketContext.Provider value={{ stocks, lastUpdateTime }}>
//       {children}
//     </WebSocketContext.Provider>
//   );
// };

// export { WebSocketProvider, WebSocketContext };






// import React, { createContext, useState, useEffect } from "react";
// import WebSocketService from "./websocketService";

// const WebSocketContext = createContext();

// const WebSocketProvider = ({ children, resource, stockIDs }) => {
//   const [stocks, setStocks] = useState({});
//   const [lastUpdateTime, setLastUpdateTime] = useState(null);

//   useEffect(() => {
//     const updateStockData = (data) => {
//       console.log("Stock data received: ", data);
//       setStocks((prevStocks) => ({
//         ...prevStocks,
//         [data.Symbol]: data, // Assuming data has a 'Symbol' key
//       }));
//       setLastUpdateTime(new Date());
//     };

//     const wsService = new WebSocketService(
//       resource,
//       stockIDs,
//       updateStockData,
//       setLastUpdateTime
//     );
//     wsService.connect();

//     return () => {
//       wsService.disconnect();
//     };
//   }, [resource, stockIDs]);

//   return (
//     <WebSocketContext.Provider value={{ stocks, lastUpdateTime }}>
//       {children}
//     </WebSocketContext.Provider>
//   );
// };

// export { WebSocketProvider, WebSocketContext };



import React, { createContext, useState, useEffect, useRef } from 'react';
import WebSocketService from './websocketService'; // Assuming WebSocketService handles WebSocket connections
import config from './config'; // Assuming you have a config file with API_BASE_URL

const WebSocketContext = createContext();

const WebSocketProvider = ({ user_id, children, resource, stockIDs }) => {
  const [stocks, setStocks] = useState({});
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [trades, setTrades] = useState([]);
  const [positions, setPortfolio] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [userScripts, setUserScripts] = useState({});
  const [stockids, setStockids] = useState([]);
  const wsServiceRef = useRef(null);
  const [isRefreshing, setRefreshing] = useState(false);

  const submitRejectionLog = async (data) => {
    try {
      
      const response = await fetch(`${config.API_BASE_URL}createRejectionLog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: data,
      });
      const dataa = await response.json();
      
      // // Sort the data by orderId in descending order
      // const sortedData = data.sort((a, b) => b.orderId - a.orderId);
      
      // setTrades(sortedData); // Assuming data is an array of trades
      // setRefreshing(false);
    } catch (error) {
      // console.error('Error fetching trades:', error);
      // setRefreshing(false);
    }
  };
 
  // Function to fetch trades from API
  const fetchTrades = async (user_id) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}getOrders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id }),
      });
      const data = await response.json();
      
      // Sort the data by orderId in descending order
      const sortedData = data.sort((a, b) => b.orderId - a.orderId);
      
      setTrades(sortedData); // Assuming data is an array of trades
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching trades:', error);
      setRefreshing(false);
    }
  };

  // performanceFromMonday

  // Function to fetch performance from API
  const fetchPerformance = async (user_id) => {
    
    try {
      const response = await fetch(`${config.API_BASE_URL}performanceFromMonday`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id }),
      });
      const data = await response.json();

      // Sort the data by orderId in descending order
      const sortedData = data.sort((a, b) => b.closing_order.orderId - a.closing_order.orderId);

      // /console.log("Data Received : "+data[0]['orderId']);
      setPerformance(sortedData); // Assuming data is an array of portfolio positions
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
      setRefreshing(false);
    }
  };

  // Function to fetch portfolio from API
  const fetchPortfolio = async (user_id) => {
    
    try {
      const response = await fetch(`${config.API_BASE_URL}portfolio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id }),
      });
      const data = await response.json();
      // /console.log("Data Received : "+data[0]['orderId']);

      const instrumentTokens = data.map(script => script.instrument_token);
      console.log(instrumentTokens);
      //setStockids(instrumentTokens);
      wsServiceRef.current.mergerIDs(instrumentTokens);
      //reconnectWebSocket();

      
      const sortedData = data.sort((a, b) => b.orderId - a.orderId);

      setPortfolio(sortedData); // Assuming data is an array of portfolio positions
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
      setRefreshing(false);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}getUserDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
        },
        body: JSON.stringify({ user_id }),
      });
      const data = await response.json();
      setUserDetails(data); // Assuming data is an object of user details
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };



  const fetchUserScripts = async (wsService) => {
    try 
    {  
      const clientId = user_id;
        
      const response = await fetch(`${config.API_BASE_URL}getUserScriptsWithPrice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
        },
        body: JSON.stringify({ clientId }),
      });
      
      const data = await response.json();
      
      setUserScripts(data); // Assuming data is an object of user details
      
      const tradingsymbols = data.map(script => script.tradingsymbol);
      const instrumentTokens = data.map(script => script.instrument_token);
      
      setStockids(instrumentTokens);
      
      //console.log("calling wsService"+data[0]['tradingsymbol']);
      wsServiceRef.current.setIDs(instrumentTokens);
      //wsService.connect();
      reconnectWebSocket();

      //alert("refresh");

      fetchTrades(user_id);
      fetchPortfolio(user_id);
      fetchPerformance(user_id);
      fetchUserDetails();

      setRefreshing(false);
      
      //console.log("user script fetched..");
    } catch (error) {
      console.error('Error fetching user details:', error);
      setRefreshing(false);
    }
  };

  const fetchUserScriptsExternal = () => {
    setRefreshing(true);
    fetchUserScripts(wsServiceRef);
  };

  useEffect(() => {

    // Initialize WebSocketService and connect
    wsServiceRef.current = new WebSocketService(
      resource,
      stockids,
      updateStockData,
      setLastUpdateTime
    );
    fetchUserScripts(wsServiceRef.current);

    //alert("changed");
    

    // Fetch initial trades and portfolio on component mount
    // fetchTrades(user_id);
    // fetchPortfolio(user_id);
    // fetchPerformance(user_id);
    // fetchUserDetails();

    // Clean up WebSocketService on unmount
    return () => {
      wsServiceRef.current.disconnect();
    };
  }, [resource, stockIDs]);

  const updateStockData = (data) => {
    //console.log('Stock data received::', data);
    setStocks((prevStocks) => ({
      ...prevStocks,
      [data.Symbol]: data, // Assuming data has a 'Symbol' key
    }));
    setLastUpdateTime(new Date());
  };


  const reconnectWebSocket = () => {
    if (wsServiceRef.current) {
      if (wsServiceRef.current.readyState === WebSocket.OPEN){
        wsServiceRef.current.disconnect(); // Disconnect the existing connection
        // wsServiceRef = null;
        // wsServiceRef.current = new WebSocketService(
        //   resource,
        //   stockids,
        //   updateStockData,
        //   setLastUpdateTime
        // );
        
        wsServiceRef.current.connect(); // Reconnect WebSocket
      }
      else{
        wsServiceRef.current.connect(); // connect WebSocket
      }
    }
  };

  // Function to manually refresh trades and portfolio data
  const refreshTradesAndPortfolio = (refreshType) => {
    try{
      setRefreshing(true);
      if(refreshType=="trades"){
        fetchTrades(user_id);
      }
      else if(refreshType=="portfolio"){
        fetchPortfolio(user_id);
      }
      else if(refreshType=="both"){
        fetchTrades(user_id);
        fetchPortfolio(user_id);
        fetchPerformance(user_id);
        fetchUserDetails();
      }
      //setRefreshing(false);
    }catch(error){
      setRefreshing(false);
    }
  };

  const refreshPerformance = () => {
    setRefreshing(true);
    fetchPerformance(user_id);
  };

  return (
    <WebSocketContext.Provider value={{ isRefreshing, stocks, userScripts, lastUpdateTime, trades, positions, performance, userDetails, fetchUserScriptsExternal, refreshPerformance, fetchUserScripts, refreshTradesAndPortfolio, submitRejectionLog }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export { WebSocketProvider, WebSocketContext };
