// import React, { useEffect, useState } from "react";
// import config from '../config';

// function Ledger() {
//   const [data, setData] = useState([]);
//   const [openingBalance, setOpeningBalance] = useState(0);
//   const [isIOSDevice, setIsIOSDevice] = useState(false);
//   const [isAndroidDevice, setIsAndroidDevice] = useState(false);

//   useEffect(() => {
//     // Fetch data from API
//     fetchData();
//     // Determine device type
//     setIsIOSDevice(/iPad|iPhone|iPod/.test(navigator.userAgent));
//     setIsAndroidDevice(/Android/.test(navigator.userAgent));
//   }, []);

//   const fetchData = async () => {
//     try {
//       const clientId = Number(localStorage.getItem('userId'));
//       const response = await fetch(`${config.API_BASE_URL}ledger`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ user_id: clientId }),
//       });
//       const dataa = await response.json();
//       setData(dataa);

//       // Calculate opening balance
//       if (dataa.length > 0) {
//         const firstTransaction = dataa[0];
//         let initialBalance = firstTransaction.mainAmount;
//         if (firstTransaction.transactionType === 1) {
//           initialBalance -= firstTransaction.transactionAmount;
//         } else if (firstTransaction.transactionType === 2) {
//           initialBalance += firstTransaction.transactionAmount;
//         }
//         setOpeningBalance(initialBalance);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   // Function to get the week number from a date
//   const getWeekNumber = (date) => {
//     const dt = new Date(date);
//     dt.setHours(0, 0, 0, 0);
//     dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
//     const week1 = new Date(dt.getFullYear(), 0, 4);
//     return 1 + Math.round(((dt - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
//   };

//   const getStartDateOfWeek = (weekNumber, year) => {
//     const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
//     const dayOfWeek = date.getDay();
//     const ISOweekStart = date;
//     if (dayOfWeek <= 4) ISOweekStart.setDate(date.getDate() - date.getDay() + 1);
//     else ISOweekStart.setDate(date.getDate() + 8 - date.getDay());
//     return ISOweekStart.toISOString().split("T")[0];
//   };

//   const getEndDateOfWeek = (weekNumber, year) => {
//     const startDate = getStartDateOfWeek(weekNumber, year);
//     const endDate = new Date(new Date(startDate).getTime() + 4 * 24 * 60 * 60 * 1000);
//     return endDate.toISOString().split("T")[0];
//   };

//   const groupedTradeOrders = data.reduce((acc, tradeOrder) => {
//     const orderDate = new Date(tradeOrder.dateTime).getTime();
//     const weekNumber = getWeekNumber(tradeOrder.dateTime);
//     const year = new Date(tradeOrder.dateTime).getFullYear();

//     const weekStartDate = getStartDateOfWeek(weekNumber, year);
//     const weekEndDate = getEndDateOfWeek(weekNumber, year);

//     if (!acc[weekNumber]) acc[weekNumber] = [];

//     tradeOrder.weekStartDate = weekStartDate;
//     tradeOrder.weekEndDate = weekEndDate;
//     tradeOrder.weekNumber = weekNumber;
//     tradeOrder.weekStartDateFormatted = new Date(weekStartDate).toLocaleDateString("en-GB");
//     tradeOrder.weekEndDateFormatted = new Date(weekEndDate).toLocaleDateString("en-GB");

//     acc[weekNumber].push(tradeOrder);
//     return acc;
//   }, {});

//   const tableToCSV = () => {
//     const csvData = [];
//     const rows = document.getElementsByTagName("tr");

//     for (let i = 0; i < rows.length; i++) {
//       const cols = rows[i].querySelectorAll("td,th");
//       const csvRow = [];
//       for (let j = 0; j < cols.length; j++) {
//         if (cols[j].innerHTML.includes("open")) continue;
//         csvRow.push(cols[j].innerHTML);
//       }
//       csvData.push(csvRow.join(","));
//     }
//     downloadCSVFile(csvData.join("\n"));
//   };

//   const downloadCSVFile = (csvData) => {
//     const CSVFile = new Blob([csvData], { type: "text/csv" });
//     const tempLink = document.createElement("a");
//     tempLink.download = "ledger.csv";
//     const url = window.URL.createObjectURL(CSVFile);
//     tempLink.href = url;
//     tempLink.style.display = "none";
//     document.body.appendChild(tempLink);
//     tempLink.click();
//     document.body.removeChild(tempLink);
//   };

//   return (
//     <main id="main" className="main" style={{ marginLeft: "0px" }}>
//       <div className="pagetitle" style={{ marginBottom: "1rem", display: "none" }}>
//         <div style={{ float: "left" }}>
//           <h1>Ledger</h1>
//         </div>
//         <div style={{ float: "right" }} className="noprint">
//           <img src="pdf_icon.png" onClick={() => window.print()} style={{ height: "30px", cursor: "pointer" }} alt="PDF" />
//           <img src="excel_icon.png" onClick={tableToCSV} style={{ height: "30px", cursor: "pointer" }} alt="Excel" />
//         </div>
//         <div style={{ clear: "both" }}></div>
//       </div>
//       <section className="section" style={{ paddingTop: isIOSDevice || isAndroidDevice ? "61px" : "" }}>
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="card" style={{ background: "transparent" }}>
//               <div className="card-body" style={{ marginTop: "10px" }}>
//                 <ul style={{ padding: "0px" }}>
//                   <li style={{ display: "block", background: "white", borderRadius: "5px", boxShadow: "0px 0px 6px -2px rgba(0,0,0,0.1)" }}>
//                     <table>
//                       <tr>
//                         <td colSpan="2">Opening Balance</td>
//                         <td style={{ textAlign: "right", fontWeight: "bold" }}>{Number(openingBalance).toFixed(2)}</td>
//                       </tr>
//                     </table>
//                   </li>
//                   {Object.keys(groupedTradeOrders).map((weekKey) => (
//                     <React.Fragment key={weekKey}>
//                       {groupedTradeOrders[weekKey].map((order) => (
//                         <li key={order.transactionId} style={{ display: "block", background: "white", borderRadius: "5px", boxShadow: "0px 0px 6px -2px rgba(0,0,0,0.1)" }}>
//                           <table>
//                             <tr>
//                               <td style={{ paddingBottom: "0px", fontSize: "12px" }}>{order.autoRemark}</td>
//                               <td style={{ paddingBottom: "0px", fontSize: "11px", textAlign: "right", verticalAlign: "top" }}>{new Date(order.dateTime).toLocaleDateString("en-GB")}</td>
//                             </tr>
//                             <tr>
//                               <td>
//                                 <p style={{ margin: "0px", fontSize: "11px" }}>Amount</p>
//                                 <p style={{ margin: "0px", fontWeight: "600" }}>
//                                   {order.transactionType === 1 ? (
//                                     <span style={{ color: "green" }}>{Number(order.transactionAmount).toFixed(2)}</span>
//                                   ) : (
//                                     <span style={{ color: "red" }}>-{Number(order.transactionAmount).toFixed(2)}</span>
//                                   )}
//                                 </p>
//                               </td>
//                               <td style={{ textAlign: "right" }}>
//                                 <p style={{ margin: "0px", fontSize: "11px" }}>Balance</p>
//                                 <p style={{ margin: "0px", fontWeight: "600" }}>{Number(openingBalance).toFixed(2)}</p>
//                               </td>
//                             </tr>
//                           </table>
//                         </li>
//                       ))}
//                       {/* Weekly Summary */}
//                       <li style={{ display: "block", background: "white", borderRadius: "5px", boxShadow: "0px 0px 6px -2px rgba(0,0,0,0.1)" }}>
//                         <table>
//                           <tr>
//                             <td style={{ paddingBottom: "0px", textAlign: "left", fontSize: "12px" }}>
//                               WEEK: {new Date(groupedTradeOrders[weekKey][0].weekStartDate).toLocaleDateString("en-GB")} - {new Date(groupedTradeOrders[weekKey][groupedTradeOrders[weekKey].length - 1].weekEndDate).toLocaleDateString("en-GB")}
//                             </td>
//                             <td style={{ paddingBottom: "0px", textAlign: "right", fontSize: "12px" }}>
//                               {/* <a target="_blank" rel="noopener noreferrer" href={`trade_week_app.php?uid=${userId}&un=${userName}&w=${weekKey}`}> */}
//                               <a target="_blank" rel="noopener noreferrer" href={`trade_week_app.php`}>
//                                 OPEN
//                               </a>
//                             </td>
//                           </tr>
//                         </table>
//                       </li>
//                     </React.Fragment>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </main>
//   );
// }

// export default Ledger;

import React, { useEffect, useState } from "react";
import config from '../config';

const getStartDateOfWeek = (weekNumber, year) => {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    date.setDate(date.getDate() - date.getDay() + 1);
    return date.toISOString().split('T')[0];
};

const getEndDateOfWeek = (weekNumber, year) => {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    date.setDate(date.getDate() - date.getDay() + 5);
    return date.toISOString().split('T')[0];
};

const Ledger = () => {
    const [data, setData] = useState([]);
    const [openingBalance, setOpeningBalance] = useState(0);
    const [groupedTradeOrders, setGroupedTradeOrders] = useState({});
    
    useEffect(() => {
        const fetchData = async () => {
            const userId = Number(localStorage.getItem('userId'));
            const response = await fetch(`${config.API_BASE_URL}ledger`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            const dataa = await response.json();
            processData(dataa);
        };

        fetchData();
    }, []);

    const calculateOpeningBalance = (dataa) => {
        if (dataa.length > 0) {
            const firstData = dataa[0];
            const { mainAmount, transactionAmount, transactionType, jvTransactionType } = firstData;

            let openingBalance = mainAmount;

            if (transactionType === 1) {
                openingBalance -= transactionAmount;
            } else if (transactionType === 2) {
                openingBalance += transactionAmount;
            } else if (transactionType === 3) {
                const jvType = jvTransactionType;
                if (jvType === 1) {
                    openingBalance -= transactionAmount;
                } else if (jvType === 2) {
                    openingBalance += transactionAmount;
                }
            }

            return openingBalance;
        }
        return 0;
    };

    const groupTradeOrdersByWeek = (dataa) => {
        const groupedTradeOrders = {};
        const currentWeek = new Date().getWeekNumber();
        
        dataa.forEach(tradeOrder => {
          //console.log(tradeOrder);
            const orderDate = new Date(tradeOrder.dateTime);
            const weekNumber = orderDate.getWeekNumber();
            //console.log(weekNumber);
            const year = orderDate.getFullYear();
            
            const weekStartDate = getStartDateOfWeek(weekNumber, year);
            const weekEndDate = getEndDateOfWeek(weekNumber, year);

            tradeOrder.weekStartDate = weekStartDate;
            tradeOrder.weekEndDate = weekEndDate;
            tradeOrder.week_number = weekNumber;
            tradeOrder.weekStartDate_formatted = new Date(weekStartDate).toLocaleDateString();
            tradeOrder.weekEndDate_formatted = new Date(weekEndDate).toLocaleDateString();

            if (tradeOrder.isTrade == 0) {
                if (!groupedTradeOrders[weekNumber]) {
                    groupedTradeOrders[weekNumber] = [];
                }
                groupedTradeOrders[weekNumber].push(tradeOrder);
                console.log(weekNumber+" : "+tradeOrder.isTrade+" , "+tradeOrder.transactionAmount);
            } else {
                const weekStart = new Date();
                weekStart.setDate(orderDate.getDate() - orderDate.getDay() + 1); // Last Monday

                let weekEnd;
                if (orderDate.getDay() <= 5) {
                    weekEnd = orderDate;
                } else {
                    weekEnd = new Date();
                    weekEnd.setDate(orderDate.getDate() + (12 - orderDate.getDay())); // Next Friday
                }

                if (orderDate >= weekStart && orderDate <= weekEnd) {
                    const current_week = new Date().getWeekNumber();

                    if (weekNumber == current_week && orderDate.getDay() !== 0) {
                        tradeOrder.currentWeekTrade = 1;
                    }

                    if (!groupedTradeOrders[weekNumber]) {
                        groupedTradeOrders[weekNumber] = [];
                    }
                    groupedTradeOrders[weekNumber].push(tradeOrder);
                }
            }
        });

        return groupedTradeOrders;
    };

    // Utility function to get week number
    Date.prototype.getWeekNumber = function() {
        const date = new Date(this.getFullYear(), this.getMonth(), this.getDate());
        const dayNum = date.getDay() || 7; 
        date.setDate(date.getDate() + 4 - dayNum);
        const yearStart = new Date(date.getFullYear(), 0, 1);
        const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
        return weekNo;
    };

    const processData = (dataa) => {
        let openingBalance = 0;
        
        setData(dataa);
        setOpeningBalance(calculateOpeningBalance(dataa));
        setGroupedTradeOrders(groupTradeOrdersByWeek(dataa));
    };

    const tableToCSV = () => {
        let csv_data = [];
        const rows = document.querySelectorAll('tr');
        rows.forEach((row) => {
            const cols = row.querySelectorAll('td, th');
            let csvrow = [];
            cols.forEach((col) => {
                if (col.innerHTML.includes('open')) return;
                csvrow.push(col.innerHTML);
            });
            csv_data.push(csvrow.join(","));
        });
        csv_data = csv_data.join('\n');
        downloadCSVFile(csv_data);
    };

    const downloadCSVFile = (csv_data) => {
        const CSVFile = new Blob([csv_data], { type: "text/csv" });
        const temp_link = document.createElement('a');
        temp_link.download = "ledger.csv";
        const url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
        temp_link.click();
        document.body.removeChild(temp_link);
    };

    // Utility functions for date formatting
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    };

    let mainAmount = 0;

    return (
        <ul style={{ padding: 0 }}>
            <li style={{ display: 'block', background: 'white', borderRadius: '5px', boxShadow: '0px 0px 6px -2px rgba(0,0,0,0.1)' }}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2">Opening Balance</td>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{Number(openingBalance).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </li>
            
            {Object.keys(groupedTradeOrders).map(weekKey => {
                const weekOrder = groupedTradeOrders[weekKey];
                //console.log(weekOrder);
                let weekTotal = 0;
                let currentOpeningBalance = openingBalance;
                //let mainAmount = currentOpeningBalance;
                return (
                    <React.Fragment key={weekKey}>
                        
                        {weekOrder.map(order => {
                            
                            if (order.isTrade === 0 || order.currentWeekTrade) {
                                const transactionAmount = order.transactionAmount;
                                const transactionType = order.transactionType;
                                const jvTransactionType = order.jvTransactionType || 0;

                                if (transactionType === 1) {
                                    mainAmount += transactionAmount;
                                    currentOpeningBalance += transactionAmount;
                                } else if (transactionType === 2) {
                                    mainAmount -= transactionAmount;
                                    currentOpeningBalance -= transactionAmount;
                                } else if (transactionType === 3) {
                                    if (jvTransactionType === 1) {
                                        mainAmount += transactionAmount;
                                        currentOpeningBalance += transactionAmount;
                                    } else if (jvTransactionType === 2) {
                                        mainAmount -= transactionAmount;
                                        currentOpeningBalance -= transactionAmount;
                                    }
                                }
                            }

                            return (
                                <li style={{ display: 'block', background: 'white', borderRadius: '5px', boxShadow: '0px 0px 6px -2px rgba(0,0,0,0.1)' }} key={order.transactionId}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{ paddingBottom: '0px', fontSize: '12px' }}>{order.autoRemark}</td>
                                                <td style={{ paddingBottom: '0px', fontSize: '11px', textAlign: 'right', verticalAlign: 'top' }}>{formatDate(order.dateTime)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p style={{ margin: '0px', fontSize: '11px' }}>Amount</p>
                                                    <p style={{ margin: '0px', fontWeight: '600', color: order.transactionType === 1 ? 'green' : 'red' }}>
                                                        {order.transactionType === 1 ? '+' : '-'}
                                                        {Number(order.transactionAmount).toFixed(2)}
                                                    </p>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <p style={{ margin: '0px', fontSize: '11px' }}>Balance</p>
                                                    <p style={{ margin: '0px', fontWeight: '600' }}>{Number(mainAmount).toFixed(2)}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            );
                        })}
                        {false && <li style={{ display: 'block', background: 'white', borderRadius: '5px', boxShadow: '0px 0px 6px -2px rgba(0,0,0,0.1)' }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ paddingBottom: '0px', textAlign: 'left', fontSize: '12px' }}>
                                            WEEK: {weekOrder[0].weekStartDate_formatted} - {weekOrder[weekOrder.length - 1].weekEndDate_formatted}
                                        </td>
                                        <td style={{ paddingBottom: '0px', textAlign: 'right', fontSize: '12px' }}>
                                            {/* <a target="_blank" rel="noopener noreferrer" href={`trade_week_app.php?uid=${userId}&un=${userName}&w=${weekOrder[0].week_number}&s=${weekOrder[0].weekStartDate}&e=${weekOrder[weekOrder.length - 1].weekEndDate}`}>
                                                Open
                                            </a> */}
                                            Open
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'left', fontSize: '12px' }}>
                                            <p style={{ margin: '0px', fontSize: '11px' }}>Amount</p>
                                            <p style={{ margin: '0px', fontWeight: '600', color: weekTotal < 0 ? 'red' : 'green' }}>
                                                {Number(weekTotal).toFixed(2)}
                                            </p>
                                        </td>
                                        <td style={{ textAlign: 'right', fontSize: '12px' }}>
                                            <p style={{ margin: '0px', fontSize: '11px' }}>Balance</p>
                                            <p style={{ margin: '0px', fontWeight: '600' }}>{Number(currentOpeningBalance + weekTotal).toFixed(2)}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>}
                    </React.Fragment>
                );
            })}
        </ul>
    );
};

const round = (number, decimalPlaces) => {
  return Number(Number(number).toFixed(decimalPlaces));
};

export default Ledger;

