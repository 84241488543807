// import React, { useEffect, useState } from 'react';
// import TradeCard from './../components/TradeCard';
// import config from '../config';
// import './../Trades.css';

// const Trades = () => {
//   const [trades, setTrades] = useState([]);

//   useEffect(() => {
//     const fetchTrades = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}getOrders`);
//         const data = await response.json();
//         setTrades(data);
//       } catch (error) {
//         console.error('Error fetching trades:', error);
//       }
//     };

//     fetchTrades();
//   }, []);

//   return (
//     <div className="trades-page">
//       {trades.map((trade) => (
//         <TradeCard key={trade.orderId} trade={trade} />
//       ))}
//     </div>
//   );
// };

// export default Trades;





// import React, { useEffect, useState } from 'react';
// import TradeCard from './../components/TradeCard';
// import config from '../config';
// import './../Trades.css';

// const Trades = () => {
//   const [trades, setTrades] = useState([]);
//   const user_id = '689239'; // Replace with your actual user_id

//   useEffect(() => {
//     const fetchTrades = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}getOrders`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         setTrades(data); // Assuming data is an array of trades
//       } catch (error) {
//         console.error('Error fetching trades:', error);
//       }
//     };

//     fetchTrades();
//   }, [user_id]);

//   return (
//     <div className="trades-page">
//       {trades.map((trade) => (
//         <TradeCard key={trade.orderId} trade={trade} />
//       ))}
//     </div>
//   );
// };

// export default Trades;




// import React, { useContext } from 'react';
// import TradeCard from './../components/TradeCard';
// import { WebSocketContext } from "../WebSocketContext";

// const Trades = () => {
//   const { trades, stocks, refreshTradesAndPortfolio } = useContext(WebSocketContext);

//   const handleRefresh = () => {
//     refreshTradesAndPortfolio("trades");
//   };

//   return (
//     <div className="trades-page">
//       <button onClick={handleRefresh}>Refresh Trades</button>
//       {/* Render trades using stocks from WebSocketContext */}
//       {trades.map(trade => (
//         <TradeCard 
//           key={trade.tradeId} 
//           stock={stocks[trade.symbol]} // Access real-time stock data from WebSocketContext
//           trade={trade} 
//         />
//       ))}
//     </div>
//   );
// }

// export default Trades;




import React, { useContext, useState } from 'react';
import TradeCard from './../components/TradeCard';
import { WebSocketContext } from "../WebSocketContext";
import './../Trades.css'; // Assuming you have a CSS file for styles
import { Refresh } from '@mui/icons-material';

const Trades = () => {
  const { trades, stocks, refreshTradesAndPortfolio } = useContext(WebSocketContext);
  const [filter, setFilter] = useState('executed');

  const handleRefresh = () => {
    refreshTradesAndPortfolio("both");
  };

  const filteredTrades = trades.filter(trade => {
    if (filter === 'executed') return trade.orderStatus == "2" || trade.orderStatus == "3";
    if (filter === 'pending') return trade.orderStatus == "1";
    if (filter === 'cancelled') return trade.orderStatus == "4";
    return true;
  });

  return (
    <div className="trades-page">
      <div className="toggle-switch" style={{width:'255px', margin:'0px auto 10px'}}>
        <button 
          className={filter === 'pending' ? 'active' : ''} 
          onClick={() => setFilter('pending')}
        >
          Pending
        </button>
        <button 
          className={filter === 'executed' ? 'active' : ''} 
          onClick={() => setFilter('executed')}
        >
          Executed
        </button>
        <button 
          className={filter === 'cancelled' ? 'active' : ''} 
          onClick={() => setFilter('cancelled')}
        >
          Cancelled
        </button>
      </div>
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      {/* Render trades using stocks from WebSocketContext */}
      {filteredTrades.map(trade => (
        <TradeCard 
          key={trade.orderId} 
          refreshTradesAndPortfolio={refreshTradesAndPortfolio}
          stock={stocks[trade.symbol]} // Access real-time stock data from WebSocketContext
          trade={trade} 
        />
      ))}
    </div>
  );
}

export default Trades;

