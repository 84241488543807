import React from 'react';
import './../PerformanceCard.css'; // Assuming you will style the component similarly to TradeCard
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PerformanceCard = ({ performance }) => {
    
    
    const pnlColor = performance.closing_order.pnl > 0 ? "green" : "red";
    const quantityColor = performance.buy_sell === '1' ? 'green' : 'red';
    const quantityColor2 = performance.closing_order.buy_sell === '1' ? 'green' : 'red';
    

    const formatDate = (dateString) => {
        // const date = new Date(dateString);
        // const day = date.getDate();
        // const month = date.toLocaleString('en-US', { month: 'long' });
        // return `${day} ${month} ${date.getFullYear()}`;
        const date = new Date(dateString);
        const day = date.getDate();
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' });
        return `${day}-${month}-${year}`;
      };

    return (
        <div className="position-card">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="position-scriptName">
                        {performance.stockName} &nbsp;
                        <span className='position-autocut'>
                            {/* {formatDate(performance.autoCutDate)} */}
                            {`QTY : ${performance.orderQuantity}`}
                        </span>
                    </h4>
                </div>
                <div className="text-end">
                    <p className={`position-pnl ${pnlColor}`}>₹{ Number(Number(performance.closing_order.pnl)-(Number(performance.brokerage) + Number(performance.closing_order.brokerage))).toFixed(2) }</p>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center" style={{marginTop:'3px'}}>
                <div>
                    {/* <p className='position-dateTime'>Autocut: {new Date(position.autoCutDate).toLocaleString()}</p> */}
                    <span className={`position-quantity ${quantityColor}`}>
                        {/* {performance.buy_sell === '1' ? `Buy:${performance.orderQuantity}` : `Sell:-${performance.orderQuantity}`} */}
                        {performance.buy_sell === '1' ? `Buy @ ${performance.orderPrice}` : `Sell @ ${performance.orderPrice}`}
                    </span>
                    {performance.orderType != '1' && <span className="position-market" >
                        {/* {performance.orderType === '1' ? `MARKET` : (performance.orderType === '2' ? `LIMIT` : `SL`)} */}
                        { performance.orderType === '2' ? `LIMIT` : `SL` }
                    </span>}
                </div>
                <div>
                    {/* <p className='position-dateTime'>Autocut: {new Date(position.autoCutDate).toLocaleString()}</p> */}
                    {performance.closing_order.orderType != '1' && <span className="position-market">
                        {/* {performance.closing_order.orderType === '1' ? `MARKET` : (performance.closing_order.orderType === '2' ? `LIMIT` : `SL`)} */}
                        { performance.closing_order.orderType === '2' ? `LIMIT` : `SL` }
                    </span>}
                    <span className={`position-quantity ${quantityColor2}`} style={{marginLeft:'10px', marginRight:'0px'}}>
                        {performance.closing_order.buy_sell === '1' ? `Buy @ ${performance.closing_order.orderPrice}` : `Sell @ ${performance.closing_order.orderPrice}`}
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center" style={{marginTop:'5px'}}>
                <span className='position-autocut'>
                    {performance.exeDateTime}
                </span>
                <span className='position-autocut'>
                    {performance.closing_order.exeDateTime}
                </span>
            </div>
            
        </div>
    );
}

export default PerformanceCard;
