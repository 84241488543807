import React, {useState, useEffect} from 'react';
import config from '../config'; // Assuming you have a config file with API_BASE_URL

const BlockedScripts = () => {

    const [blockedScripts, setBlockedScripts] = useState({});

    const getBlockedSymbols = async (wsService) => {
        try 
        {  
          const response = await fetch(`${config.API_BASE_URL}getBlockedSymbols`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
            },
            body: JSON.stringify({ }),
          });
          
          const data = await response.json();
          
          setBlockedScripts(data); 

        } catch (error) {
          console.error('Error fetching blocked scripts:', error);
        }
    };

    useEffect(() => {
        getBlockedSymbols();
      }, []);

  return (
    
    <>
    {blockedScripts.length > 0 && Object.keys(blockedScripts).length > 0 ? (
        blockedScripts.map(script => {
          return (
            <h5>{script.dispname}</h5>
          );
        })
      ) : (
        <p>Loading blocked scripts...</p>
      )}
    </>
  );
}

export default BlockedScripts;

