import React, { useContext, useState, useEffect } from 'react';
import LogCard from './../components/LogCard';
import { WebSocketContext } from "../WebSocketContext";
import './../Trades.css'; // Assuming you have a CSS file for styles
import { Refresh } from '@mui/icons-material';
import config from '../config'; // Assuming you have a config file with API_BASE_URL

const RejectionLogs = () => {

    const [rejectionLogs, setRejectionLogs] = useState([]);
    const user_id = Number(localStorage.getItem('userId'));

    const getRejectionLogs = async () => {
        try 
        {  
          const response = await fetch(`${config.API_BASE_URL}getTodayRejectionLog`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
            },
            body: JSON.stringify({ user_id, user_type:'client' }),
          });
          
          const data = await response.json();
          
          setRejectionLogs(data); 

        } catch (error) {
          console.error('Error fetching rejection logs:', error);
        }
    };

    useEffect(() => {
        getRejectionLogs();
      }, []);

    const handleRefresh = () => {
        getRejectionLogs();
    };

  return (
    <div className="trades-page">
      
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      
      {rejectionLogs.map(log => (
        <LogCard 
          key={log.id} 
          log={log} 
        />
      ))}
    </div>
  );
}

export default RejectionLogs;

