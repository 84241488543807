// websocketService.js

class WebSocketService {
    constructor(resource, stockIDs, updateStockData, setLastUpdateTime) {
      this.resource = resource;
      this.stockIDs = stockIDs;
      this.updateStockData = updateStockData;
      this.setLastUpdateTime = setLastUpdateTime;
      this.socket = null;
      this.intervalId = null;
      this.jsLastUpdate = null;
      //console.log("service called"+stockIDs[0]);
    }

    setIDs(ids){
      this.stockIDs = ids;
    }

    mergerIDs(newIds){
      //this.stockIDs = this.stockIDs.concat(newIds);
      this.stockIDs = [...new Set([...this.stockIDs, ...newIds])];
    }
  
    connect() {
      const url =
        this.resource === "zerodha"
          ? 'wss://papertrade.co.in:8083'
          : 'wss://feed1.globalmarketcap.org/stream2/?access_token=crec4219d680f1d1ec480ef1685330a3'; // Add the alternative URL here
  
      this.socket = new WebSocket(url);
  
      this.socket.onopen = () => {
        console.log("WebSocket connection opened");
  
        if (this.resource === "zerodha") {
          this.stockIDs.forEach((item) => {
            //console.log(item);
            const instrumentIdentifier = item;//Object.keys(item)[0]; //"CRUDEOILM24JULFUT";
            this.socket.send(JSON.stringify({ subscribe: instrumentIdentifier }));
            // this.socket.send(
            //   `42["subscribe_symbol",{"room":"${instrumentIdentifier}"}]`
            // );
          });
  
          setInterval(() => {
            this.socket.send("2");
          }, 28000);
        } else {
          this.socket.send(
            JSON.stringify({ method: "subscribe", params: this.stockIDs })
          );
        }

        // Start the interval for the refresh check
        this.startRefreshCheck();

      };
  
      this.socket.onclose = () => {
        console.log("WebSocket connection closed");
        //alert("connection closed");
      };
  
      this.socket.onmessage = (event) => {
        //this.jsLastUpdate = new Date().getTime
        this.handleMessage(event);
        //alert(event);
      };
  
      this.socket.onerror = (event) => {
        console.error("WebSocket error:", event);
        // /alert("Connection error"+JSON.stringify(event, null ,2));
        //alert("Connection error"+event.isTrusted);
      };
    }
  
    handleMessage(event) {
      const response = event.data;
  
      let jsonData;
      try {
        if (response.includes("ping")) {
          this.socket.send(JSON.stringify("pong"));
        } else if (response.includes(42)) {
          const trimmedResponse = response.slice(2);
          jsonData = JSON.parse(trimmedResponse);
        } else {
          jsonData = JSON.parse(response);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
  
      if (jsonData) {
        if (this.resource === "zerodha" && jsonData[0] === "scrip_data") {
          const data = jsonData[1].data;
          this.updateStockData(data);
        } else {
          this.updateStockData(jsonData);
        }
      }
  
      const currentTime = new Date();
      this.setLastUpdateTime(currentTime);
      this.jsLastUpdate = currentTime; // Update the last update time
    }
  
    disconnect() {
      if (this.socket) {
        this.socket.close();
      }
      // Stop the interval for the refresh check
      this.stopRefreshCheck();
    }

    startRefreshCheck() {
      this.intervalId = setInterval(this.refreshCheck.bind(this), 5000);
    }
  
    stopRefreshCheck() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  
    refreshCheck() {
      if (this.jsLastUpdate != null) {
        const nowTime = new Date();
        const timeDiff = Math.abs(nowTime.getTime() - this.jsLastUpdate.getTime());
        const timeDiffInSecond = Math.ceil(timeDiff / 1000);
  
        if (timeDiffInSecond > 5) {
          this.socket.close();
          this.connect();
        }
      }
    }
  }
  
  export default WebSocketService;
  