// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
//import { useAuth } from './contexts/AuthContext';
//import Cookies from "js-cookie";
import { getToken, storeToken, removeToken } from './utils/auth'; // Adjust path as needed


const ProtectedRoute = ({ element }) => {
    //const {  token ,userId } = useAuth();
    //const user = Cookies.get("user");

    //const token = getToken().value;
    const token = localStorage.getItem('authToken');

  return token ? element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
