import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationNotifier = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ url: location.pathname }));
    }
  }, [location]);

  return null;
};

export default NavigationNotifier;
