// import React from 'react';
// import './../BottomSheet.css'; // Import your CSS file for styling

// const BottomSheet = ({ isOpen, onClose, stock }) => {
//   return (
//     <div className={`bottom-sheet ${isOpen ? 'open' : ''}`}>
//       <div className="bottom-sheet-header">
//         <h3>{stock.dispname}</h3>
//         <button onClick={onClose}>Close</button>
//       </div>
//       <div className="bottom-sheet-body">
//         {/* Stock details */}
//         <div className="stock-details">
//           <p>Last Price: {stock.lastPrice}</p>
//           <p>Change: {stock.change}</p>
//           {/* Add more details as needed */}
//         </div>

//         {/* Buy/Sell Depth */}
//         <div className="buy-sell-depth">
//           {/* Display buy and sell depth information */}
//         </div>

//         {/* Buy/Sell buttons */}
//         <div className="buy-sell-buttons">
//           <button className="buy-button">Buy</button>
//           <button className="sell-button">Sell</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BottomSheet;



// import React from 'react';
// import { useSpring, animated } from 'react-spring';
// import { Box, Typography, Button, IconButton, Grid } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import './../BottomSheet.css';

// const BottomSheet = ({ stock, isOpen, onClose }) => {
//   const styles = useSpring({
//     transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
//   });

//   return (
//     <animated.div style={styles} className="bottom-sheet">
//       <Box className="bottom-sheet-header">
//         <Typography variant="h6">
//           {stock.dispname} - {stock.Symbol}
//         </Typography>
//         <IconButton onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       </Box>
//       <Box className="bottom-sheet-content">
//         <Typography variant="h4" className="stock-price">
//           ₹{stock.LTP}
//         </Typography>
//         <Typography variant="body2" className="stock-change">
//           {stock.Change} ({stock.Change_per}%)
//         </Typography>
//         <Grid container spacing={2} className="stock-details">
//           <Grid item xs={6}>
//             <Typography>Open</Typography>
//             <Typography>{stock.Open}</Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>High</Typography>
//             <Typography>{stock.High}</Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>Low</Typography>
//             <Typography>{stock.Low}</Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>Prev. Close</Typography>
//             <Typography>{stock.Prev_Close}</Typography>
//           </Grid>
//         </Grid>
//         <Box className="order-depth">
//           <Typography variant="body1">Buy/Sell Depth</Typography>
//           {/* Add your buy/sell depth table here */}
//         </Box>
//         <Box className="bottom-actions">
//           <Button variant="contained" color="primary">
//             Buy
//           </Button>
//           <Button variant="contained" color="secondary">
//             Sell
//           </Button>
//         </Box>
//       </Box>
//     </animated.div>
//   );
// };

// export default BottomSheet;





import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import { Switch, FormControlLabel, Box, Typography, Button, IconButton, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import upIcon from '../assets/up_icon.png';
import downIcon from '../assets/down_icon.png';
import './../BottomSheet.css';
import { useDrag } from 'react-use-gesture';
import swal from 'sweetalert';
import { WebSocketContext } from "../WebSocketContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from './CustomToast';
import { blue } from '@mui/material/colors';

const BottomSheetPortfolio = ({ userDetails, position, isOpen, onClose }) => {
  const { refreshTradesAndPortfolio, submitRejectionLog } = useContext(WebSocketContext);
  const [disableBtn, setDisable] = useState(false);
  const styles = useSpring({
    transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
  });

  // console.log("BottomSheetPortfolio",stock);

  const showToast = (type, message) => {
    if(type==1){
      toast.success(<CustomToast title={"Trade Placed"} description={message} />, {
          position:'top-right',
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
    else if(type==2){
      toast.success(<CustomToast title={"Order Placed"} description={message} />, {
          position:'top-right',
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
    else if(type==3){
      toast.error(<CustomToast title={"Order Failed"} description={message} />, {
          position:'top-right',
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
};

  const [{ y }, api] = useSpring(() => ({ y: 0 }));

  React.useEffect(() => {
    api.start({ y: isOpen ? 0 : 100 });
  }, [isOpen, api]);

  const [isDepthExpanded, setIsDepthExpanded] = useState(true);
  const [lots, setLots] = useState(position.orderLotQuantity);
  const [quantity, setQuantity] = useState(position.lotsize);
  const [orderExecutionType, setOrderExecutionType] = useState('Market');
  const [isLimitOrder, setIsLimitOrder] = useState(false);
  const [price, setPrice] = useState('');

  const toggleDepth = () => {
    setIsDepthExpanded(!isDepthExpanded);
  };

  const prevLotsRef = useRef(lots);
  const prevQuantityRef = useRef(quantity);

  // useEffect(() => {
  //   if (isOpen) {
  //     // Initialize values based on the stock's lot size when the bottom sheet opens
  //     const initialLotSize = position.lotsize || 1;
  //     setQuantity(initialLotSize);
  //     setLots(1);
  //     prevLotsRef.current = 1;
  //     prevQuantityRef.current = initialLotSize;
  //   }
  // }, [isOpen, position.lotsize]);

  
  const handleOrderTypeToggle = (orderExecutionType) => {
    setOrderExecutionType(orderExecutionType);
    //alert("Limit Order: "+!isLimitOrder)
  };

  const buyDepth = position.live.depth?.buy || [];
  const sellDepth = position.live.depth?.sell || [];

  // Calculate the change percentage
  const _Bid = position.buyPrice = position.live.Bid!=undefined ? position.live.Bid : position.buyPrice;
  const _Ask = position.sellPrice = position.live.Ask!=undefined ? position.live.Ask : position.sellPrice;
  const _Open = position.live.Open!=undefined ? position.live.Open : position.open;
  const _High = position.live.High!=undefined ? position.live.High : position.high;
  const _Low = position.live.Low!=undefined ? position.live.Low : position.low;
  const _Prev_Close = position.live.Prev_Close!=undefined ? position.live.Prev_Close : position.close;
  const _LTP = position.live.LTP!=undefined ? position.live.LTP : position.lastTradedPrice;

  const Change_per = ((_LTP - _Prev_Close) / _Prev_Close * 100).toFixed(2);
  const Change = (_LTP - _Prev_Close).toFixed(2);
  const changeColor = Change > 0 ? "green" : "red";

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      onClose();
    }
  };

  const createOrder = async (orderType) => {
    
    setDisable(true);
    if (quantity < 1) {
      alert("Quantity should be at least 1.");
      setDisable(false);

      var data = JSON.stringify({
          clientId: userDetails.clientId,
          parent_id: userDetails.parentId,
          stockId: position.stockId,
          stockExpiry: '"'+position.expiry+'"',
          stockName: '"'+position.stockName+'"',
          orderTransactionType: 2,
          orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
          buy_sell: orderType,
          lot: position.orderLotQuantity,
          quantity: position.orderQuantity,
        remark: '"Quantity should be at least 1."',
        orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    // const nowTime = new Date();
    // const lastTradeTimeUnix = new Date(stock.lastTradeTime * 1000);
    // const timeDiffInSecond = Math.ceil((nowTime - lastTradeTimeUnix) / 1000);

    // if (position.autoCutDate <= new Date().toISOString().split('T')[0]) {
    //   //alert("Can't trade after autocut date.");
    //   return;
    // }

    let orderAmount = 0;

    if (_Bid <= 0 || _Ask <= 0 || _Bid == _Ask) 
    {
      alert("Price not updated, please refresh");
      setDisable(false);

      var data = JSON.stringify({
          clientId: userDetails.clientId,
          parent_id: userDetails.parentId,
          stockId: position.stockId,
          stockExpiry: '"'+position.expiry+'"',
          stockName: '"'+position.stockName+'"',
          orderTransactionType: 2,
          orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
          buy_sell: orderType,
          lot: position.orderLotQuantity,
          quantity: position.orderQuantity,
        remark: '"Price not updated, please refresh."',
        orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    if(orderExecutionType=="Limit" || orderExecutionType=="SL"){
      if(Number(price) < Number(position.lower_circuit) || Number(price) > Number(position.upper_circuit)) {
        alert("Order price is beyond daily price range.");
        setDisable(false);

        var data = JSON.stringify({
          clientId: userDetails.clientId,
          parent_id: userDetails.parentId,
          stockId: position.stockId,
          stockExpiry: '"'+position.expiry+'"',
          stockName: '"'+position.stockName+'"',
          orderTransactionType: 2,
          orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
          buy_sell: orderType,
          lot: position.orderLotQuantity,
          quantity: position.orderQuantity,
          remark: '"Order price is beyond daily price range."',
          orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
        });
  
        submitRejectionLog(data);

        return;
      }
    }

    if (orderExecutionType=="Limit") 
    {  
      if (orderType === 1) {
        if (price >= _Bid) {
          alert("Order price must be less than bid price.");
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: position.stockId,
            stockExpiry: '"'+position.expiry+'"',
            stockName: '"'+position.stockName+'"',
            orderTransactionType: 2,
            orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
            buy_sell: orderType,
            lot: position.orderLotQuantity,
            quantity: position.orderQuantity,
            remark: '"Order price must be less than bid price."',
            orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
          });
    
          submitRejectionLog(data);

          return;
        }
      } else {
        if (price <= _Ask) {
          alert("Order price must be greater than ask price.");
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: position.stockId,
            stockExpiry: '"'+position.expiry+'"',
            stockName: '"'+position.stockName+'"',
            orderTransactionType: 2,
            orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
            buy_sell: orderType,
            lot: position.orderLotQuantity,
            quantity: position.orderQuantity,
            remark: '"Order price must be greater than ask price."',
            orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
          });
    
          submitRejectionLog(data);

          return;
        }
      }
    }
    else if (orderExecutionType=="SL") 
    { 
        if(orderType==2){
          if(price >= _Bid){
            alert("Order price should be less than bid price."); 
            setDisable(false);

            var data = JSON.stringify({
              clientId: userDetails.clientId,
              parent_id: userDetails.parentId,
              stockId: position.stockId,
              stockExpiry: '"'+position.expiry+'"',
              stockName: '"'+position.stockName+'"',
              orderTransactionType: 2,
              orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
              buy_sell: orderType,
              lot: position.orderLotQuantity,
              quantity: position.orderQuantity,
              remark: '"Order price should be less than bid price."',
              orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
            });
      
            submitRejectionLog(data);

            return;
          }
        }
        else {
          if(price <= _Ask){
            alert("Order price should be greater than ask price."); 
            setDisable(false);

            var data = JSON.stringify({
              clientId: userDetails.clientId,
              parent_id: userDetails.parentId,
              stockId: position.stockId,
              stockExpiry: '"'+position.expiry+'"',
              stockName: '"'+position.stockName+'"',
              orderTransactionType: 2,
              orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
              buy_sell: orderType,
              lot: position.orderLotQuantity,
              quantity: position.orderQuantity,
              remark: '"Order price should be greater than ask price."',
              orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
            });
      
            submitRejectionLog(data);

            return;
          }
        }
    }

    if (orderType === 2) 
    {
        orderAmount = quantity * _Bid;
    } 
    else if (orderType === 1) {
        orderAmount = quantity * _Ask;
    }

    if (lots <= 0) {
      alert("Problem with lot quantity.");
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        stockId: position.stockId,
        stockExpiry: '"'+position.expiry+'"',
        stockName: '"'+position.stockName+'"',
        orderTransactionType: 2,
        orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
        buy_sell: orderType,
        lot: position.orderLotQuantity,
        quantity: position.orderQuantity,
        remark: '"Problem with lot quantity."',
        orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    position.dispName = position.dispname;

    position.stock = position.stock || {};

    position.stock.instrumentIdentifier = position.stockId;
    position.stock.exchange = position.exchange;
    position.stock.dispName = position.stockName;

    try {
      const response = await fetch('https://papertrade.co.in/mediator.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stock: position,
					orderTypeBuySell: orderType,
					orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
					lots: lots,
					orderTransactionType: 2,
					orderDevice: 1,
					limitSlPrice: price,
					user_id: userDetails.clientId,
          parent_id: userDetails.parentId,
					ip: process.env.REACT_APP_USER_IP,
        }),
      });
      const data = await response.json();

      if (!data.success) 
      {
          var msg = data.message;

          setDisable(false);

          if(data.message=="SL Position"){
            msg = "Please remove SL from trades.";
          }
          else if(data.message=="Position already closed."){
            msg = "Position already closed.";
          } else {
            msg = data.message;
          }

          showToast(3, msg, "Order Failed");

          var dataa = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: position.stockId,
            stockExpiry: '"'+position.expiry+'"',
            stockName: '"'+position.stockName+'"',
            orderTransactionType: 2,
            orderExecutionType: orderExecutionType=="Market" ? 1 : (orderExecutionType=="Limit" ? 2 : 3),
            buy_sell: orderType,
            lot: position.orderLotQuantity,
            quantity: position.orderQuantity,
            remark: '"'+msg+'"',
            orderPrice: (orderExecutionType=="Limit" || orderExecutionType=="SL") ? price : (orderType==1 ? position.sellPrice : position.buyPrice)
          });
    
          submitRejectionLog(dataa);

      } 
      else {
        setDisable(false);
        // var b_s = orderType == 1 ? 'Buy' : 'Sell';

        // if (orderExecutionType=="Limit" || orderExecutionType=="SL"){
        //   showToast(1, b_s+" "+lots+" Lot(s) of "+position.dispName+" @"+Number(data.orderPrice).toFixed(2));
        // }
        // else{
        //   showToast(2, b_s+" "+lots+" Lot(s) of "+position.dispName+" @"+Number(data.orderPrice).toFixed(2));
        // }
        
        onClose();

        var b_s = orderType == 1 ? 'Buy' : 'Sell';
        
        var msg = b_s+" "+Number(position.orderLotQuantity).toFixed(2)+" Lot(s) of "+position.stockName+" @"+Number(data.orderPrice).toFixed(2);

        if (orderExecutionType=="Limit" || orderExecutionType=="SL"){
          
          swal({
            title: orderExecutionType+" Order Successful",
            text: msg,
            icon: "success",
            buttons: {
              confirm: "OK"
            },
          }).then((value) => {
            if (value) {
              //window.location = `trades.php?tb=${getSelectedRadioValue()}`;
              // location.reload();
            }
          });
        } else {
          
          swal({
            title: "Order Successful",
            text: msg,
            icon: "success",
            buttons: {
              confirm: "OK"
            },
          }).then((value) => {
            if (value) {
              // window.location = "portfolio.php";
            }
          });
        }

        refreshTradesAndPortfolio('both');
        
      }
    } catch (error) {
      console.error("Error:", error);
      
      setDisable(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {isOpen && <div className="backdrop" onClick={handleBackdropClick} />}
      {isOpen && <animated.div style={{ styles }} className="bottom-sheet">
        <Box className="bottom-sheet-header">
          <Typography variant="h6" className='bottom-sheet-scriptName'>
            <p>
                <span style={{fontWeight:'600'}}>{position.stockName}</span> <span className='stock-expiry'>{position.expiry}</span>
            </p>
            <p>
              <span className='bottom-sheet-exchange'>{position.exchange}</span>
              <span className='bottom-sheet-exchange'>QTY {position.orderQuantity}</span>
            </p>
          </Typography>
        
          {/* <IconButton onClick={onClose} className='bottomSheet-close-btn'>
            <CloseIcon />
          </IconButton> */}
          <p style={{fontSize:'14px', fontWeight:'500'}}>
            <span style={{ position:' absolute', right: '20px', fontWeight: '600'}}>
              ₹{Number(_LTP).toFixed(2)}
              {Change > 0 ? (
                  <img src={upIcon} alt="Up" className="change-icon" />
              ) : (
                  <img src={downIcon} alt="Down" className="change-icon" />
              )}
            </span> <br/> 
            <span className={changeColor}>{Change}({Change_per}{'%'})</span>
          </p>
        </Box>
        <Box className="bottom-sheet-content">
          <Grid container spacing={2} className="stock-details">
            <Grid item xs={3} className='center'>
              <Typography>Open</Typography>
              <Typography className='ohlc'>{Number(_Open).toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={3} className='center'>
              <Typography>High</Typography>
              <Typography className='ohlc'>{Number(_High).toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={3} className='center'>
              <Typography>Low</Typography>
              <Typography className='ohlc'>{Number(_Low).toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={3} className='center'>
              <Typography>Close</Typography>
              <Typography className='ohlc'>{Number(_Prev_Close).toFixed(2)}</Typography>
            </Grid>
          </Grid>

          {position.exchange!='OPTSTK' && <div className="stock-details dpr" style={{marginTop:'3px !important'}}>
            <p style={{textAlign:'center', fontWeight:'600', fontSize:'12px'}}>Day Price Range: { position.lower_circuit } - { position.upper_circuit }</p>
          </div>}

          {buyDepth.length > 0 && (
            <>
              <Typography variant="h6" className='market-depth-title' onClick={toggleDepth} style={{ cursor: 'pointer', display: 'flex', justifyContent:'space-between' }}>
                 {/* <span>Market Depth {isDepthExpanded ? '-' : '+'}</span> */}
                 <span style={{fontWeight:600}}>Market Depth</span>
                 <span style={{fontWeight:600}}>Balance: {Number(userDetails.availableMargin).toFixed(2)}</span>
              </Typography>
              {isDepthExpanded && (
                <div className='market-depth'>
                  <Box className="order-depth">
                    <TableContainer component={Paper} className='noborder'>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className='left-align padding5'>Qty</TableCell>
                            <TableCell className='right-align padding5'>Buy Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {buyDepth.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className='left-align padding5 noborder'>{row.quantity}</TableCell>
                              <TableCell className='green right-align padding5 noborder'>{Number(row.price).toFixed(2)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box className="order-depth">
                    <TableContainer component={Paper} className='noborder'>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className='left-align padding5'>Sell Price</TableCell>
                            <TableCell className='right-align padding5'>Qty</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sellDepth.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className='red left-align padding5 noborder'>{Number(row.price).toFixed(2)}</TableCell>
                              <TableCell className='right-align padding5 noborder'>{row.quantity}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              )}
            </>
          )}

          {/* <Box className="lot-quantity-section">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} className='center'>
                <Typography>Lots</Typography>
                <div style={{textAlign:'center'}}>
                  <Button className='plus-minus_btn' >-</Button>
                  <input value={Number(lots).toFixed(2)} variant="outlined" size="small" readOnly className='input-text-field'/>
                  <Button className='plus-minus_btn' >+</Button>
                </div>
              </Grid>
              <Grid item xs={6} className='center'>
                <Typography>Quantity</Typography>
                <div style={{textAlign:'center'}}>
                  <Button className='plus-minus_btn' >-</Button>
                  <input value={Number(quantity)} variant="outlined" size="small" readOnly className='input-text-field'/>
                  <Button className='plus-minus_btn' >+</Button>
                </div>
              </Grid>
            </Grid>
          </Box> */}
            
            {position.exchange!='OPTSTK' && <Box className="order-type-section" style={{display:'flex'}}>
                
                <div style={{ textAlign:'center', margin:'3px auto'}}>
                    <div className="toggle-switch" style={{ width:'215px' }}>
                      <span style={{ width:'79px' }} className={orderExecutionType=="Market" ? 'active' : ''} onClick={() => handleOrderTypeToggle("Market")}>Market</span>
                      <span style={{ width:'70px' }} className={orderExecutionType=="Limit" ? 'active' : ''} onClick={() => handleOrderTypeToggle("Limit")}>Limit</span>
                      <span style={{ width:'65px' }} className={orderExecutionType=="SL" ? 'active' : ''} onClick={() => handleOrderTypeToggle("SL")}>SL</span>
                    </div>
                </div>
              
              {orderExecutionType!='Market' && (
                <div style={{ textAlign:'center'}}>
                  {/* <Button className='plus-minus_btn' >-</Button> */}
                      <input type="number"
                        label="Price"
                        variant="outlined"
                        size="small"
                        value={price}
                        placeholder='Price'
                        className='input-text-field full-width-important'
                        onChange={(e) => setPrice(e.target.value)}
                      />
                  {/* <Button className='plus-minus_btn' >+</Button> */}
                </div>
                
                    
              )}
              
            </Box>}
          
          
          
            <Box className="bottom-actions">
                {position.buy_sell==1 && <Button disabled={disableBtn} variant="contained" color="secondary" className='button-sell' onClick={() => createOrder(2)} >
                  Sell
                </Button>}
                  
                {position.buy_sell==2 && <Button disabled={disableBtn} variant="contained" color="primary" className='button-buy' onClick={() => createOrder(1)} >
                  Buy
                </Button>}
            </Box>
            
        </Box>
      </animated.div>}
    </>
  );
};

export default BottomSheetPortfolio;
