import React, { useEffect, useState, useContext } from 'react';
import PositionCard from '../components/PositionCard';
import BottomSheetPortfolio from '../components/BottomSheetPortfolio';
import { WebSocketContext } from "../WebSocketContext";
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import '../Performance.css'; // Assuming you will style the portfolio page
import PerformanceCard from '../components/PerformanceCard';
import { Refresh } from '@mui/icons-material';

const Performance = () => {

  const { performance , userDetails, refreshTradesAndPortfolio, refreshPerformance } = useContext(WebSocketContext);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  let grossPnl = 0;
  let brokerage = 0;

  //console.log("Profile userDetails",userDetails);

  const toggleDepth = () => {
      setIsDetailsExpanded(!isDetailsExpanded);
    };

  //console.log("Performance rendered");
  
  // Calculate total PnL
  const calculateTotalPnL = () => {

    if (!Array.isArray(performance)) {
      performance = [performance]; // Ensure it's an array
    }

    grossPnl = performance.reduce((total, position) => {
      const pnl = Number(position.closing_order.pnl);
      var t =Number(Number(total) + pnl).toFixed(2); 
      return t;
    }, 0);

    brokerage = performance.reduce((total, position) => {
      //const stock = stocks[position.instrument_token] || {};
      //console.log("calc pnl", position);
      var t =Number(Number(total) - Number(position.brokerage) - Number(position.closing_order.brokerage)).toFixed(2); 
      
      return t;
    }, 0);

    return performance.reduce((total, position) => {
      //const stock = stocks[position.instrument_token] || {};
      //console.log("calc pnl", position);
      const pnl = Number(position.closing_order.pnl);
      var t =Number(Number(total) + pnl - Number(position.brokerage) - Number(position.closing_order.brokerage)).toFixed(2); 
      
      return t;
    }, 0);
  };

  const handleRefresh = () => {
    refreshTradesAndPortfolio("both");
  };

  return (
    <div className="portfolio-page">
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      <span style={{display:'none'}}>{calculateTotalPnL()}</span>

      <div  className="pnl-display" onClick={toggleDepth} style={{display:'flex', padding:'0px'}}>
        <div className="pnl-display" style={{display:'block', margin:'0px'}}>
          <span className="pnl-title">Gross P&L </span>
          <p className={`pnl-value ${grossPnl < 0 ? 'negative' : 'positive'}`}>
          ₹{Number(grossPnl).toFixed(2)}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'center'}}>
          <span className="pnl-title">Brokerage </span>
          <p className={`pnl-value ${brokerage < 0 ? 'negative' : 'positive'}`}>
          ₹{Number(brokerage).toFixed(2)}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'right'}}>
          <span className="pnl-title">Net P&L </span>
          <p className={`pnl-value ${ (calculateTotalPnL()) < 0 ? 'negative' : 'positive'}`}>
          ₹{ calculateTotalPnL()}
          </p>
        </div>
        
      </div>

      {/* <div  className="pnl-display" onClick={toggleDepth} style={{display:'block'}}>
        <div className="pnl-display" style={{display:'flex', padding:'0px', margin:'0px'}}>
          <span className="pnl-title">Total P&L </span>
          <span className={`pnl-value ${calculateTotalPnL() < 0 ? 'negative' : 'positive'}`}>
            ₹{calculateTotalPnL()}
          </span>
        </div>
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Gross P&L </span>
          <span>₹{Number(grossPnl).toFixed(2)}</span>
        </div>}
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Brokerage </span>
          <span>₹{Number(brokerage).toFixed(2)}</span>
        </div>}
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Available Margin </span>
          <span>₹{Number(userDetails.availableMargin ? userDetails.availableMargin : 0).toFixed(2)}</span>
        </div>}
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Main Balance </span>
          <span>₹{Number(userDetails.mainAmount ? userDetails.mainAmount : 0).toFixed(2)}</span>
        </div>}
      </div> */}
      {performance.length > 0 && Object.keys(performance).length > 0 ? (
        performance.map(position => {
          return (
            <PerformanceCard 
              key={position.orderId} 
              performance={position}
            />
          );
        })
      ) : (
        <p>Loading performance...</p>
      )}
    </div>
  );
}

export default Performance;

