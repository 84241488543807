import React, { useState, useEffect } from "react";
import "./../StockCard.css"; // Importing the CSS file
import upIcon from '../assets/up_icon.png';
import downIcon from '../assets/down_icon.png';

const ScoreCard = ({ stock, _stock, onCardClick }) => {
  const { Symbol, Ask, Bid, LTP, High, Low, Open, Prev_Close, ExpiryDate } = stock;
  const [prevBid, setPrevBid] = useState(_stock.Bid);
  const [prevAsk, setPrevAsk] = useState(_stock.Ask);


  // Calculate the change percentage
  const _Bid = _stock.Bid!=undefined ? _stock.Bid : stock.buyPrice;
  const _Ask = _stock.Ask!=undefined ? _stock.Ask : stock.sellPrice;
  const _Open = _stock.Open!=undefined ? _stock.Open : stock.open;
  const _High = _stock.High!=undefined ? _stock.High : stock.high;
  const _Low = _stock.Low!=undefined ? _stock.Low : stock.low;
  const _Prev_Close = _stock.Prev_Close!=undefined ? _stock.Prev_Close : stock.close;
  const _LTP = _stock.LTP!=undefined ? _stock.LTP : stock.lastTradedPrice;

  const Change_per = ((_LTP - _Prev_Close) / _Prev_Close * 100).toFixed(2);
  const Change = (_LTP - _Prev_Close).toFixed(2);
  const changeColor = Change > 0 ? "green" : "red";

  const handleClick = () => {
    stock['live'] = _stock;
    onCardClick(stock);
  };

  const [bidChangeClass, setBidClass] = useState('green');
  const [askChangeClass, setAskClass] = useState('red');

  // Compare Bid and Ask with previous values
  // const bidChangeClass = _Bid > prevBid ? 'green' : _Bid < prevBid ? 'red' : '';
  // const askChangeClass = _Ask > prevAsk ? 'green' : _Ask < prevAsk ? 'red' : '';

  // Update the previous values on every render
  useEffect(() => {
    if (_Bid !== prevBid) {
      //console.log(`Bid changed: Old Bid = ${prevBid}, New Bid = ${_Bid}`);
     let a = _Bid > prevBid ? setBidClass('green') : _Bid < prevBid ? setBidClass('red') : '';
    }
    if (_Ask !== prevAsk) {
      //console.log(`Ask changed: Old Ask = ${prevAsk}, New Ask = ${_Ask}`);
      let b = _Ask > prevAsk ? setAskClass('green') : _Ask < prevAsk ? setAskClass('red') : '';
    }
    setPrevBid(_Bid);
    setPrevAsk(_Ask);
    
  }, [_Bid, _Ask]);

  return (
    <div className="stock-card" onClick={handleClick}>
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <h4 className="stock-scriptName">{stock.dispname} <span className="stock-expiry">{stock.expiry}</span></h4>
            </div>
            <div className="text-end">
                <p className={`stock-change ${changeColor}`}>{Change} ({Change_per}%)</p>
            </div>
        </div>
        {/* <p>{new Date(ExpiryDate).toLocaleDateString()}</p> */}
      
      <div className="stock-body">
          <div className="stock_LTP-continer">
              <p className={`stock-LTP stock-price `}>
                  {Number(_LTP).toFixed(2)}
              </p>
              {Change > 0 ? (
                  <img src={upIcon} alt="Up" className="change-icon" />
              ) : (
                  <img src={downIcon} alt="Down" className="change-icon" />
              )}
          </div>
          <div className="stock-body">
              {/* <div className={`stock-price ${bidChangeClass}`}>
                  <p>{Number(_Bid).toFixed(2)}</p>
              </div> &nbsp; &nbsp;
              <div className={`stock-price ${askChangeClass}`}>
                  <p>{Number(_Ask).toFixed(2)}</p>
              </div> */}
              <div className={`stock-price ${bidChangeClass}`}>
                  <p>{Number(_Bid).toFixed(2)}</p>
              </div> &nbsp; &nbsp;
              <div className={`stock-price ${askChangeClass}`}>
                  <p>{Number(_Ask).toFixed(2)}</p>
              </div>
          </div>
      </div>
      <div className="stock-body stock-HLOC">
        <p>O: {Number(_Open).toFixed(2)}</p>
        <p>H: {Number(_High).toFixed(2)}</p>
        <p>L: {Number(_Low).toFixed(2)}</p>
        <p>C: {Number(_Prev_Close).toFixed(2)}</p>
      </div>
      
    </div>
  );
};

export default ScoreCard;
