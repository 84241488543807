// import React from 'react';
// import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
// import { Home, List, AccountBalance, AccountCircle } from '@mui/icons-material';
// import { useNavigate, useLocation } from 'react-router-dom';

// function NavBar() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [value, setValue] = React.useState(location.pathname);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//     navigate(newValue);
//   };

//   return (
//     <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
//       <BottomNavigation value={value} onChange={handleChange}>
//         <BottomNavigationAction label="Watchlist" value="/" icon={<Home />} />
//         <BottomNavigationAction label="Trades" value="/trades" icon={<List />} />
//         <BottomNavigationAction label="Portfolio" value="/portfolio" icon={<AccountBalance />} />
//         <BottomNavigationAction label="Profile" value="/profile" icon={<AccountCircle />} />
//       </BottomNavigation>
//     </Paper>
//   );
// }

// export default NavBar;


import React, { useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Home, List, AccountBalance, AccountCircle, Bookmark, Book, SwapCalls, Business, BusinessCenter ,Analytics } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import './../NavBar.css'; // Import custom CSS file
import BottomTime from './BottomTime';

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} style={{backgroundColor:'rgb(212 212 212 / 40%)', backdropFilter:'blur(6px)', boxShadow:'none', borderRadius:'0px'}}>
      <BottomNavigation value={value} onChange={handleChange} style={{backgroundColor:'transparent'}}>
        <BottomNavigationAction 
          className="nav-action" 
          label="Watchlist" 
          value="/" 
          icon={<Bookmark />} 
        />
        <BottomNavigationAction 
          className="nav-action" 
          label="Trades" 
          value="/trades" 
          icon={<SwapCalls />} 
        />
        <BottomNavigationAction 
          className="nav-action" 
          label="Portfolio" 
          value="/portfolio" 
          icon={<BusinessCenter />} 
        />
        <BottomNavigationAction 
          className="nav-action" 
          label="Performance" 
          value="/performance" 
          icon={<Analytics />} 
        />
        {/* <BottomNavigationAction 
          className="nav-action" 
          label="Profile" 
          value="/profile" 
          icon={<AccountCircle />} 
        /> */}
      </BottomNavigation>
      <BottomTime />
    </Paper>
  );
}

export default NavBar;

