import React from 'react';
import './../TradeCard.css';
import { Delete } from '@mui/icons-material';
import config from '../config';

const TradeCard = ({ log }) => {
  const quantityColor = log.buy_sell === '1' ? 'green' : 'red';
  //const statusText = orderStatus === '2' ? 'COMPLETE' : 'PENDING';
  const orderTransaction = log.orderTransactionType === '1' ? 'Open' : 'Close';
  const orderTransactionColor = log.orderTransactionType === '1' ? 'green' : 'red';
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', { month: 'short' });
    return `${day}-${month}-${year}`;
  };

  const orderTypeText = (orderType, buy_sell) => {
    switch(orderType){
        case "1":
          return "MARKET";
        case "2":
          {
            switch(buy_sell){
              case "1":
                return "BUY LIMIT";
              case "2":
                return "SELL LIMIT";
            }
          }
          //return "LIMIT";
        case "3":
          return "STOP LOSS";
    }
  };

  return (
    <div className="trade-card">
        <div className="d-flex justify-content-between align-items-center">
            <h4 className="trade-scriptName">{log.stockName}</h4>
            <div className="trade-price">{Number(log.orderPrice).toFixed(2)}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
            {log.stockExpiry!=undefined && <span className="trade-dateTime">Expiry {formatDate(log.stockExpiry)}</span>}
            <span className='trade-dateTime'>{new Date(log.dateTime).toLocaleString()}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
            <div className="trade-body">
                <p className={`trade-quantity ${quantityColor}`}>
                    {log.buy_sell === '1' ? `QTY:${Number(log.quantity).toFixed(2)}` : `QTY:-${Number(log.quantity).toFixed(2)}`}
                </p>
                <p className="trade-market">{orderTypeText(log.orderExecutionType, log.buy_sell)}</p>
            </div>
            <div className="trade-body">
              <span className={`trade-transaction ${orderTransactionColor}`}>{orderTransaction}</span>
            </div>
        </div>
            <div className="trade-body">
              <span style={{fontSize:'13px', textAlign:'justify', fontWeight:'600', marginTop:'5px'}}>{log.remark}</span>
            </div>
            
        
    </div>
  );
};

export default TradeCard;
