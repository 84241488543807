// utils/auth.js

// Set a cookie with a name, value, and expiration time
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value}; ${expires}; path=/`;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const storeToken = async (data) => {
    console.log("token stored: ", data.token);
    localStorage.setItem('authToken', data.token);
    localStorage.setItem('userId', data.clientId);

    setCookie('appilix_push_notification_user_identity', data.clientId, 30); // '30' is for 30 days
    var appilix_push_notification_user_identity = data.clientId;
    //alert(getCookie('appilix_push_notification_user_identity'));
  };
  
  export const getToken = async () => {
    const token = localStorage.getItem('authToken');
    //console.log("token read: ", token);
    return token ? token : null;
  };
  
  export const removeToken = async () => {
    console.log("token removed ");
    localStorage.removeItem('authToken');
  };
  