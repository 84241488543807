// import React, { useState, useEffect } from 'react';
// import '../AddScript.css';

// const AddScript = () => {
//   const [segment, setSegment] = useState('MCX FUT');
//   const [search, setSearch] = useState('');
//   const [scripts, setScripts] = useState([]);

//   // Dummy data for demonstration
//   const allScripts = {
//     'MCX FUT': ['COPPER30AUG2024', 'CRUDEOIL19AUG2024', 'GOLD05AUG2024', 'SILVER05SEP2024'],
//     'NSE FUT': ['NIFTY30AUG2024', 'BANKNIFTY19AUG2024'],
//     'NSE OPT': ['NIFTY19AUG2024PE', 'BANKNIFTY19AUG2024CE']
//   };

//   useEffect(() => {
//     // Fetch and set scripts based on selected segment
//     setScripts(allScripts[segment]);
//   }, [segment]);

//   const handleScriptClick = (script) => {
//     // Handle the click event to call an API
//     console.log(`Selected script: ${script}`);
//     // Example API call
//     // fetch(`/api/add-script?script=${script}`)
//     //   .then(response => response.json())
//     //   .then(data => console.log(data));
//   };

//   const filteredScripts = scripts.filter(script =>
//     script.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <div className="add-script-container">
//       <div className="segment-buttons">
//         {Object.keys(allScripts).map(seg => (
//           <button
//             key={seg}
//             className={`segment-button ${segment === seg ? 'active' : ''}`}
//             onClick={() => setSegment(seg)}
//           >
//             {seg}
//           </button>
//         ))}
//       </div>
//       <input
//         type="text"
//         placeholder="Search..."
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         className="search-bar"
//       />
//       <div className="script-list">
//         {filteredScripts.map((script, index) => (
//           <div
//             key={index}
//             className="script-item"
//             onClick={() => handleScriptClick(script)}
//           >
//             {script}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddScript;


// import React, { useState, useEffect, useContext } from 'react';
// import '../AddScript.css';
// import config from '../config';
// import { WebSocketContext } from "../WebSocketContext";
// import { Bookmark } from '@mui/icons-material';

// const AddScript = () => {
//     const { fetchUserScripts, userScripts } = useContext(WebSocketContext);
//     const [scriptsData, setScriptData] = useState([]);
//   const [segment, setSegment] = useState('MCX FUT');
//   const [search, setSearch] = useState('');
//   const [selectedStrike, setSelectedStrike] = useState('');
//   const [optionType, setOptionType] = useState('All'); // Filter for options (CE/PE)
//   const [existingScripts, setExistingScripts] = useState(new Set());

//   useEffect(() => {
//     // Create a set of existing instrumentIdentifiers from the provided data
//     if (Array.isArray(userScripts)){
//         const identifiers = new Set(userScripts.map(item => item.instrumentIdentifier));
//         setExistingScripts(identifiers);
//     }
//   }, [fetchUserScripts]);

//   const getSegmentFromExchange = (exchange) => {
//     switch (exchange) {
//       case 'FUTCOM': return 'MCX FUT';
//       case 'FUTSTK': return 'NSE FUT';
//       case 'OPTSTK': return 'NSE OPT';
//       case 'MCX FUT': return 'FUTCOM';
//       case 'NSE FUT': return 'FUTSTK';
//       case 'NSE OPT': return 'OPTSTK';
//       default: return '';
//     }
//   };

//     const getScripts = async (segment, gby) => {
//         try 
//         {  
//             var jsn = {};
//             jsn = gby ? {"exchange":segment,"groupBy":gby} : {"exchange":segment,"groupBy":gby, "allstrike": true };

//             const response = await fetch(`${config.API_BASE_URL}scripts`, {
//                 method: 'POST',
//                 headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
//                 },
//                 body: JSON.stringify(jsn),
//             });
            
//             const data = await response.json();
            
//             setScriptData(data); 

//         } catch (error) {
//             console.error('Error fetching scripts:', error);
//         }
//     };

//     const addScriptToClient = async (script) => {
//         try 
//         {  
//             const clientId = localStorage.getItem('userId');

//             var jsn = {"clientId":clientId,"stockId":script.instrumentIdentifier};

//             const response = await fetch(`${config.API_BASE_URL}addScriptToUser`, {
//                 method: 'POST',
//                 headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
//                 },
//                 body: JSON.stringify(jsn),
//             });
            
//             const data = await response.json();
//             console.log(data);
//             if(data.success){
//                 alert(script.scriptName+" added successfully");
//                 fetchUserScripts();
//             }
//             //setScriptData(data); 

//         } catch (error) {
//             console.error('Error fetching scripts:', error);
//         }
//     };

//     const getOPTSTKScripts = async (script) => {
//         try 
//         {  
//             var jsn = {"scriptName":script.scriptName,"expiry":script.expiry,"cpboth":true};
//             //jsn = gby ? {"exchange":segment,"groupBy":gby} : {"exchange":segment,"groupBy":gby, "allstrike": true };

//             const response = await fetch(`${config.API_BASE_URL}OPTSTKStrikes`, {
//                 method: 'POST',
//                 headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
//                 },
//                 body: JSON.stringify(jsn),
//             });
            
//             const data = await response.json();
            
//             setScriptData(data); 

//         } catch (error) {
//             console.error('Error fetching scripts:', error);
//         }
//     };

//     //getScripts(getSegmentFromExchange(segment));

//       useEffect(() => {
//         var seg = getSegmentFromExchange(segment);
        
//         var gby = true;
        
//         if(seg=='OPTSTK') gby = false;
        
//         getScripts(seg, gby);

//       }, [segment]);

//       useEffect(() => {
//         if(selectedStrike=='' && segment === 'NSE OPT'){
//             var seg = getSegmentFromExchange(segment);
            
//             var gby = true;
            
//             if(seg=='OPTSTK') gby = false;
            
//             getScripts(seg, gby);
//         }

//       }, [selectedStrike]);



//   const handleScriptClick = (script) => {
//     console.log(`Selected script: ${JSON.stringify(script)}`);
//     if(script.exchange=='OPTSTK' && selectedStrike==''){
//         setScriptData([]);
//         setSelectedStrike(script);
//         getOPTSTKScripts(script);
//     }
//     else{
//         console.log("add me");
//         addScriptToClient(script);
//     }
//     // Example API call
//     // fetch(`/api/add-script?script=${script}`)
//     //   .then(response => response.json())
//     //   .then(data => console.log(data));
//   };

//   const handleOptionTypeClick = (type) => {
//     setOptionType(type);
//   };

//   const clearOPTSTK = () => {
//     setScriptData([]);
//     setSelectedStrike('');
//     // setSegment('NSE OPT');
//     // alert(segment);
//   };

//   const filteredScripts = scriptsData
//     .filter(script => getSegmentFromExchange(script.exchange) === segment)
//     .filter(script => script.dispname.toLowerCase().includes(search.toLowerCase()))
//     .filter(script => segment !== 'NSE OPT' || optionType === 'All' || script.call_put === optionType);

//   return (
//     <div className="add-script-container">
        
//       <div className="segment-buttons">
//         {['MCX FUT', 'NSE FUT', 'NSE OPT'].map(seg => (
//           <button
//             key={seg}
//             className={`segment-button ${segment === seg ? 'active' : ''}`}
//             onClick={() => setSegment(seg)}
//           >
//             {seg}
//           </button>
//         ))}
//       </div>

//       {selectedStrike && <h4 onClick={() => clearOPTSTK()}>{ `< ${selectedStrike.scriptName}`}<p className='expiry'>{selectedStrike.expiry}</p></h4>}

//       { selectedStrike && segment === 'NSE OPT' && (
//         <div className="option-type-buttons">
//           {['CE', 'PE'].map(type => (
//             <button
//               key={type}
//               className={`option-type-button ${optionType === type ? 'active' : ''}`}
//               onClick={() => handleOptionTypeClick(type)}
//             >
//               {type === 'All' ? 'All' : type === 'CE' ? 'Call (CE)' : 'Put (PE)'}
//             </button>
//           ))}
//         </div>
//       )}

//       <input
//         type="text"
//         placeholder="Search..."
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         className="search-bar"
//       />

//       <div className="script-list">
//         { getSegmentFromExchange(segment)!="OPTSTK" && filteredScripts.map((script, index) => (
//           <div
//             key={index}
//             className="script-item"
//             onClick={() => handleScriptClick(script)}
//           >
//             {/* {script.scriptName} {script.strike !== '0' ? script.strike : ''} {script.call_put} */}
//             {script.scriptName}
//             <p className='expiry'>{script.expiry}</p>
//             {existingScripts.has(script.instrumentIdentifier) && <Bookmark style={{color:'#2e559d'}} />}
//           </div>
//         ))}
//         { selectedStrike && filteredScripts.map((script, index) => (
//           <div
//             key={index}
//             className="script-item"
//             onClick={() => handleScriptClick(script)}
//           >
//             {script.scriptName} {script.strike !== '0' ? script.strike : ''} {script.call_put}
//             <p className='expiry'>{script.expiry}</p>
//           </div>
//         ))}
//         { selectedStrike=='' && filteredScripts.map((script, index) => (
//           <div
//             key={index}
//             className="script-item"
//             onClick={() => handleScriptClick(script)}
//           >
//             {script.scriptName}
//             <p className='expiry'>{script.expiry}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddScript;


// import React, { useState, useEffect, useContext } from 'react';
// import '../AddScript.css';
// import config from '../config';
// import { WebSocketContext } from "../WebSocketContext";
// import { Bookmark } from '@mui/icons-material';

// const AddScript = () => {
//   const { fetchUserScripts, userScripts } = useContext(WebSocketContext);
//   const [scriptsData, setScriptData] = useState([]);
//   const [segment, setSegment] = useState('MCX FUT');
//   const [search, setSearch] = useState('');
//   const [selectedStrike, setSelectedStrike] = useState('');
//   const [optionType, setOptionType] = useState('CE'); // Filter for options (CE/PE)
//   const [existingScripts, setExistingScripts] = useState(new Set());

//   useEffect(() => {
//     if (Array.isArray(userScripts)) {
//       const identifiers = new Set(userScripts.map(item => item.instrumentIdentifier));
//       setExistingScripts(identifiers);
//     }
//   }, [userScripts]);

//   useEffect(() => {
//     const seg = getSegmentFromExchange(segment);
//     const gby = seg === 'OPTSTK' ? false : true;
//     getScripts(seg, gby);
//   }, [segment, selectedStrike]);

//   const getSegmentFromExchange = (exchange) => {
//     switch (exchange) {
//       case 'FUTCOM': return 'MCX FUT';
//       case 'FUTSTK': return 'NSE FUT';
//       case 'OPTSTK': return 'NSE OPT';
//       case 'MCX FUT': return 'FUTCOM';
//       case 'NSE FUT': return 'FUTSTK';
//       case 'NSE OPT': return 'OPTSTK';
//       default: return '';
//     }
//   };

//   const getScripts = async (segment, gby) => {
//     try {
//       const jsn = gby ? { "exchange": segment, "groupBy": gby } : { "exchange": segment, "allstrike": true };
//       const response = await fetch(`${config.API_BASE_URL}scripts`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer <YOUR_TOKEN>`
//         },
//         body: JSON.stringify(jsn),
//       });
//       const data = await response.json();
//       setScriptData(data);
//     } catch (error) {
//       console.error('Error fetching scripts:', error);
//     }
//   };

//   const addScriptToClient = async (script) => {
//     try {
//       const clientId = localStorage.getItem('userId');
//       const jsn = { "clientId": clientId, "stockId": script.instrumentIdentifier };

//       const response = await fetch(`${config.API_BASE_URL}addScriptToUser`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer <YOUR_TOKEN>`
//         },
//         body: JSON.stringify(jsn),
//       });
      
//       const data = await response.json();
//       if (data.success) {
//         alert(`${script.scriptName} added successfully`);
//         fetchUserScripts();
//       }
//     } catch (error) {
//       console.error('Error adding script:', error);
//     }
//   };

//   const getOPTSTKScripts = async (script) => {
//     try {
//       const jsn = { "scriptName": script.scriptName, "expiry": script.expiry, "cpboth": true };
//       const response = await fetch(`${config.API_BASE_URL}OPTSTKStrikes`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer <YOUR_TOKEN>`
//         },
//         body: JSON.stringify(jsn),
//       });
//       const data = await response.json();
//       setScriptData(data);
//     } catch (error) {
//       console.error('Error fetching OPTSTK scripts:', error);
//     }
//   };

//   const handleScriptClick = (script) => {
//     if (script.exchange === 'OPTSTK' && selectedStrike === '') {
//       setScriptData([]);
//       setSelectedStrike(script);
//       getOPTSTKScripts(script);
//     } else {
//       addScriptToClient(script);
//     }
//   };

//   const handleOptionTypeClick = (type) => {
//     setOptionType(type);
//   };

//   const clearOPTSTK = () => {
//     setScriptData([]);
//     setSelectedStrike('');
//   };

//   const filteredScripts = scriptsData
//     .filter(script => getSegmentFromExchange(script.exchange) === segment)
//     .filter(script => script.dispname.toLowerCase().includes(search.toLowerCase()))
//     .filter(script => segment === 'NSE OPT' || optionType === 'All' || script.call_put === optionType);

//   return (
//     <div className="add-script-container">
//       <div className="segment-buttons">
//         {['MCX FUT', 'NSE FUT', 'NSE OPT'].map(seg => (
//           <button
//             key={seg}
//             className={`segment-button ${segment === seg ? 'active' : ''}`}
//             onClick={() => setSegment(seg)}
//           >
//             {seg}
//           </button>
//         ))}
//       </div>

//       {selectedStrike && (
//         <h4 onClick={clearOPTSTK}>
//           {`< ${selectedStrike.scriptName}`}
//           <p className="expiry">{selectedStrike.expiry}</p>
//         </h4>
//       )}

//       {selectedStrike && segment === 'NSE OPT' && (
//         <div className="option-type-buttons">
//           {['All', 'CE', 'PE'].map(type => (
//             <button
//               key={type}
//               className={`option-type-button ${optionType === type ? 'active' : ''}`}
//               onClick={() => handleOptionTypeClick(type)}
//             >
//               {type === 'All' ? 'All' : type === 'CE' ? 'Call (CE)' : 'Put (PE)'}
//             </button>
//           ))}
//         </div>
//       )}

//       <input
//         type="text"
//         placeholder="Search..."
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         className="search-bar"
//       />

//       <div className="script-list">
//         {filteredScripts.map((script, index) => (
//           <div
//             key={index}
//             className="script-item"
//             onClick={() => handleScriptClick(script)}
//           >
//             {script.scriptName}
//             {selectedStrike!='' && <span>&nbsp; {script.strike}</span>}
//             {selectedStrike!='' && <span>&nbsp; {script.call_put}</span>}
//             <p className="expiry">{script.expiry}</p>
//             {existingScripts.has(script.instrumentIdentifier) && <Bookmark style={{ color: '#2e559d' }} />}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddScript;


import React, { useState, useEffect, useContext } from 'react';
import '../AddScript.css';
import config from '../config';
import { WebSocketContext } from "../WebSocketContext";
import { Bookmark } from '@mui/icons-material';

const AddScript = () => {
  const { fetchUserScripts, userScripts } = useContext(WebSocketContext);
  const [scriptsData, setScriptData] = useState([]);
  const [segment, setSegment] = useState('MCX FUT');
  const [search, setSearch] = useState('');
  const [selectedStrike, setSelectedStrike] = useState('');
  const [optionType, setOptionType] = useState('All');
  const [existingScripts, setExistingScripts] = useState(new Set());

  useEffect(() => {
    if (Array.isArray(userScripts)) {
      const identifiers = new Set(userScripts.map(item => item.instrumentIdentifier));
      setExistingScripts(identifiers);
    }
  }, [userScripts]);

  useEffect(() => {
    setSelectedStrike('');
    const seg = getSegmentFromExchange(segment);
    const gby = seg !== 'OPTSTK';
    getScripts(seg, gby);
  }, [segment]);

  const getSegmentFromExchange = (exchange) => {
    switch (exchange) {
      case 'FUTCOM': return 'MCX FUT';
      case 'FUTSTK': return 'NSE FUT';
      case 'OPTSTK': return 'NSE OPT';
      case 'MCX FUT': return 'FUTCOM';
      case 'NSE FUT': return 'FUTSTK';
      case 'NSE OPT': return 'OPTSTK';
      default: return '';
    }
  };

  const getScripts = async (segment, gby) => {
    try {
      var jsn = "";
      if(segment=='FUTCOM' || segment=='FUTSTK'){
        jsn = { "exchange": segment};
      }else{
        jsn = gby ? { "exchange": segment, "groupBy": gby } : { "exchange": segment, "allstrike": true };
      }
      const response = await fetch(`${config.API_BASE_URL}scripts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer <YOUR_TOKEN>`
        },
        body: JSON.stringify(jsn),
      });
      const data = await response.json();
      setScriptData(data);
    } catch (error) {
      console.error('Error fetching scripts:', error);
    }
  };

  const addScriptToClient = async (script) => {
    try {
      const clientId = localStorage.getItem('userId');
      const jsn = { "clientId": clientId, "stockId": script.instrumentIdentifier };

      const response = await fetch(`${config.API_BASE_URL}addScriptToUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer <YOUR_TOKEN>`
        },
        body: JSON.stringify(jsn),
      });
      
      const data = await response.json();
      if (data.success) {
        // alert(`${script.scriptName} added successfully`);
        fetchUserScripts();
      }
    } catch (error) {
      console.error('Error adding script:', error);
    }
  };

  const deleteScriptFromUser = async (script) => {
    try {
        const clientId = localStorage.getItem('userId');
      const response = await fetch(`${config.API_BASE_URL}removeScriptFromUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: Number(clientId),
          stockId: Number(script.instrumentIdentifier)
        }),
      });

      const data = await response.json();
      if (data.success) {
        // alert(`${script.scriptName} removed successfully`);
        fetchUserScripts();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const removeScriptFromClient = async (script) => {
    try {
      const clientId = localStorage.getItem('userId');
      const jsn = { "clientId": Number(clientId), "stockId": Number(script.instrumentIdentifier) };
      console.log("data", jsn);

      const response = await fetch(`${config.API_BASE_URL}deleteScriptFromUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsn),
      });
      
      const data = await response.json();
      if (data.success) {
        alert(`${script.scriptName} removed successfully`);
        fetchUserScripts();
      }
    } catch (error) {
      console.error('Error adding script:', error);
    }
  };

  const getOPTSTKScripts = async (script) => {
    try {
      const jsn = { "scriptName": script.scriptName, "expiry": script.expiry, "cpboth": true };
      const response = await fetch(`${config.API_BASE_URL}OPTSTKStrikes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer <YOUR_TOKEN>`
        },
        body: JSON.stringify(jsn),
      });
      const data = await response.json();
      if(data){
        setScriptData(data);
      }
      else{
        setScriptData([]);
      }
    } catch (error) {
      console.error('Error fetching OPTSTK scripts:', error);
    }
  };

  const handleScriptClick = (script) => {
    if (script.exchange === 'OPTSTK' && !selectedStrike) {
      setScriptData([]);
      setSelectedStrike(script);
      getOPTSTKScripts(script);
    } else {
        if (existingScripts.has(script.instrumentIdentifier)) {
            //callAnotherMethod(script);
            //alert("Already exist.");
            deleteScriptFromUser(script);
          }
          else {
            addScriptToClient(script);
          }
    }
  };

  const handleOptionTypeClick = (type) => {
    setOptionType(type);
  };

  const clearOPTSTK = () => {
    setScriptData([]);
    //setSegment('NSE OPT');
    setSelectedStrike('');
    const seg = getSegmentFromExchange(segment);
    const gby = seg !== 'OPTSTK';
    getScripts(seg, gby);
    setOptionType("All");
  };

  const filteredScripts = scriptsData
    .filter(script => getSegmentFromExchange(script.exchange) === segment)
    .filter(script => script.dispname.toLowerCase().includes(search.toLowerCase()))
    .filter(script => segment !== 'NSE OPT' || optionType === 'All' || script.call_put === optionType);

  return (
    <div className="add-script-container">
      <div className="segment-buttons">
        {['MCX FUT', 'NSE FUT', 'NSE OPT'].map(seg => (
          <button
            key={seg}
            className={`segment-button ${segment === seg ? 'active' : ''}`}
            onClick={() => setSegment(seg)}
          >
            {seg}
          </button>
        ))}
      </div>

      {selectedStrike && (
        <h4 onClick={clearOPTSTK}>
          {`< ${selectedStrike.scriptName}`}
          <p className="expiry">{selectedStrike.expiry}</p>
        </h4>
      )}

      {selectedStrike && segment === 'NSE OPT' && (
        <div className="option-type-buttons">
          {['All', 'CE', 'PE'].map(type => (
            <button
              key={type}
              className={`option-type-button ${optionType === type ? 'active' : ''}`}
              onClick={() => handleOptionTypeClick(type)}
            >
              {type === 'All' ? 'All' : type === 'CE' ? 'Call (CE)' : 'Put (PE)'}
            </button>
          ))}
        </div>
      )}

      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="search-bar"
      />

      <div className="script-list">
        {filteredScripts.map((script, index) => (
          <div
            key={index}
            className="script-item"
            onClick={() => handleScriptClick(script)}
          >
            {script.scriptName}
            {selectedStrike && <span>&nbsp; {script.strike}</span>}
            {selectedStrike && <span>&nbsp; {script.call_put}</span>}
            <p className="expiry">{script.expiry}</p>
            {existingScripts.has(script.instrumentIdentifier) && <Bookmark style={{ color: '#319197' }} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddScript;
